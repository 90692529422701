import '../../css/footer.css';
import FooterNavbar from "../FooterNavbar/index";
import Social from "../Social/index";
import FooterForm from "../FooterForm/index";
import FooterSubNav from "../FooterSubNav/index";

function Footer() {
	return (
		<footer className="footer">
			<div className="footer__container">
				<div className="footer__top">
					<div className="footer__nav-social">
						<FooterNavbar></FooterNavbar>
						<Social></Social>
					</div>
					<FooterForm></FooterForm>
				</div>
				<div className="footer__bottom">
					<FooterSubNav></FooterSubNav>
					<div className="footer__copyright">&copy;{new Date().getFullYear()} Pandion Web Development, All Rights Reserved</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
