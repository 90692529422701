import React from "react";
import {NavLink} from "react-router-dom";

function FooterSubNav() {
	return (
		<div className="footer__sub-nav">
			<nav>
				<div className="footer__sub-nav-container">
					<div>
						<ul>
							<li className="footer__sub-nav-item">
								<NavLink className="footer__sub-nav-link" to="/privacy-policy">
									Privacy Policy
								</NavLink>
							</li>
							<li className="footer__sub-nav-item">
								<NavLink className="footer__sub-nav-link" to="/terms">
									Terms & Conditions
								</NavLink>
							</li>
							<li className="footer__sub-nav-item">
								<NavLink className="footer__sub-nav-link" to="/cookies">
									Cookies Policy
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default FooterSubNav;