import React from "react";
import {NavLink} from "react-router-dom";

function FooterNav() {
	return (
		<div className="footer__nav">
			<nav>
				<div className="footer__nav-container">
					<div>
						<ul>
							<li className="footer__nav-item">
								<NavLink className="footer__nav-link" to="/">
									Home
								</NavLink>
							</li>
							<li className="footer__nav-item">
								<NavLink className="footer__nav-link" to="/services">
									Services
								</NavLink>
							</li>
							<li className="footer__nav-item">
								<NavLink className="footer__nav-link" to="/about">
									About
								</NavLink>
							</li>
							<li className="footer__nav-item">
								<NavLink className="footer__nav-link" to="/contact">
									Contact
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default FooterNav;