// Filename - pages/Services.js

import React from "react";
import Header from "../components/Header/index";
import Hero from "../components/Hero/index";
import image from "../assets/hero.png";
import Footer from "../components/Footer";

const Services = () => {
	return (
		<div>
			<Header></Header>
			<Hero content={{ heading: 'Services', subText: '' }} heroImage={image}></Hero>
			<h1>
				Services
			</h1>
			<Footer></Footer>
		</div>
	);
};

export default Services;
