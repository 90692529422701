import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import Home from "./pages";
import Services from "./pages/services";
import About from "./pages/about";
import Contact from "./pages/contact";
import './css/settings.css';

function App() {
	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/services" element={<Services />} />
				<Route path="/about" element={<About />}/>
				<Route path="/contact" element={<Contact />}/>
			</Routes>
		</Router>
	);
}

export default App;
