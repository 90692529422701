import {useState} from "react";

export default function FooterForm() {
	const [formData, setFormData] = useState({email: "", message: ""});

	const handleChange = (event) => {
		const {name, value} = event.target;
		setFormData((prevFormData) => ({...prevFormData, [name]: value}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		alert(`Name: ${formData.name}, Email: ${formData.email}, Message: ${formData.message}`
		);
	};

	return (
		<div className="footer__form">
			<h2>Stay Updated on Pandion News</h2>
			<form onSubmit={handleSubmit}>
				<label htmlFor="email">Email Address:</label>
				<input type="email" id="email" name="email" placeholder="Email Address:" value={formData.email} onChange={handleChange}/>
				<button type="submit">Submit</button>
			</form>
		</div>
	);
}