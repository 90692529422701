import '../../css/header.css';
import logo from '../../assets/logo.png';
import NavBar from "../../components/Navbar/index";

function Header() {
	return (
		<div className="header">
			<div className="header__logo">
				<img src={logo} alt="Pandion Web Development Logo" />
			</div>

			<NavBar></NavBar>
		</div>
	);
}

export default Header;
