// Filename - pages/index.js

import React from "react";
import Header from "../components/Header/index";
import Hero from "../components/Hero/index";
import Footer from "../components/Footer/index";
import image from '../assets/hero.png';

const Home = () => {
	return (
		<div>
			<Header></Header>
			<Hero content={{ heading: 'Crafting Digital Excellence by the Bay', subText: 'Elevate your online presence with our bespoke web design and development services in Weymouth, Nova Scotia. Where innovation meets coastal charm, we transform visions into captivating digital experiences.' }} heroImage={image}></Hero>
			<h1>Home</h1>
			<Footer></Footer>
		</div>
	);
};

export default Home;