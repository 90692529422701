import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';

function Navigation() {
	const [isWideScreen, setIsWideScreen] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(min-width: 768px)');

		const handleMediaQueryChange = (mediaQueryList) => {
			setIsWideScreen(mediaQueryList.matches);
		};

		// Initial check
		handleMediaQueryChange(mediaQuery);

		// Add listener for changes
		mediaQuery.addListener(handleMediaQueryChange);

		// Clean up the listener on component unmount
		return () => mediaQuery.removeListener(handleMediaQueryChange);
	}, []);

	return (
		<div className={`header__nav ${isWideScreen ? '' : 'header__nav--sub-menu' }`}>
			<nav>
				<div className="header__nav-container">
					<div>
						<ul>
							<li className="header__nav-item">
								<NavLink className="header__nav-link" to="/">
									Home
								</NavLink>
							</li>
							<li className="header__nav-item">
								<NavLink className="header__nav-link" to="/services">
									Services
								</NavLink>
							</li>
							<li className="header__nav-item">
								<NavLink className="header__nav-link" to="/about">
									About
								</NavLink>
							</li>
							<li className="header__nav-item">
								<NavLink className="header__nav-link" to="/contact">
									Contact
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Navigation;
