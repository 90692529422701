import '../../css/hero.css';

function Hero({ content, heroImage }) {
	return (
		<div className="hero">
            <div className="hero__img-container">
	            <picture>
		            <source media="(min-width:0px)" srcSet={heroImage} />
		            <img src={heroImage} alt="Gilberts Cove Lighthouse" />
	            </picture>
            </div>
			<div className="hero__content-container">
				<div className="hero__content">
					<span className="hero__heading">{content.heading}</span>
					<p>{content.subText}</p>
				</div>
			</div>
		</div>
	);
}

export default Hero;
