import React from "react";
import fb from '../../assets/fb.png';
import ig from '../../assets/ig.png';
import linked from '../../assets/linked.png';
import snap from '../../assets/snap.png';
import x from '../../assets/x.png';

function Social() {
	return (
		<div className="footer__social">
			<a href="https://www.facebook.com" rel="noreferrer" target="_blank"><img src={fb} alt="Facebook Logo"/></a>
			<a href="https://www.instagram.com" rel="noreferrer" target="_blank"><img src={ig} alt="Instagram Logo"/></a>
			<a href="https://www.linkedin.com" rel="noreferrer" target="_blank"><img src={linked} alt="LinkedIn Logo"/></a>
			<a href="https://www.snapchat.com" rel="noreferrer" target="_blank"><img src={snap} alt="Snapchat Logo"/></a>
			<a href="https://www.x.com" rel="noreferrer" target="_blank"><img src={x} alt="X Logo"/></a>
		</div>
	);
}

export default Social;